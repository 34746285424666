var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"success","inline":"","title":"Import"}},[_c('v-form',[_c('v-tabs',{attrs:{"color":"primary"}},[_c('v-tab',[_vm._v("Marc")]),_c('v-tab',[_vm._v("Linear")]),_c('v-tab-item',[_c('v-row',{staticClass:"text-no-wrap",attrs:{"dense":_vm.dense,"align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-inline-flex"},[_c('v-autocomplete',{attrs:{"label":"Prefix","items":[
                        'cln',
                        'mon',
                        'inv',
                        'ath',
                        'rmf',
                        'prv',
                        'art',
                        'evn',
                        'srv',
                        'sub',
                        'udc',
                        'crw',
                        'dig',
                        'ing',
                        'ind'
                      ]},model:{value:(_vm.prefix),callback:function ($$v) {_vm.prefix=$$v},expression:"prefix"}}),_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.getSchema}},[_vm._v("Schema")]),_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.getMachetaSchema}},[_vm._v("Macheta")]),_c('v-btn',{on:{"click":_vm.getTMSchema}},[_vm._v("Table")])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{attrs:{"label":"Pagina"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('v-text-field',{attrs:{"label":"Pagina maxima"},model:{value:(_vm.maxPage),callback:function ($$v) {_vm.maxPage=$$v},expression:"maxPage"}}),_c('v-btn',{on:{"click":_vm.importRecords}},[_vm._v(_vm._s(_vm.importing ? "Stop" : "Import"))])],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{attrs:{"label":"Record"},model:{value:(_vm.recordImport),callback:function ($$v) {_vm.recordImport=$$v},expression:"recordImport"}}),_c('v-btn',{on:{"click":_vm.importRecord}},[_vm._v("Import record")])],1)])],1)],1),_c('v-tab-item',[_c('v-row',{staticClass:"text-no-wrap",attrs:{"dense":_vm.dense,"align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{attrs:{"label":"Prefix"},model:{value:(_vm.table),callback:function ($$v) {_vm.table=$$v},expression:"table"}}),_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.getLinearSchema}},[_vm._v("Schema")]),_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.getLinearMacheta}},[_vm._v("Macheta")]),_c('v-btn',{on:{"click":_vm.getLinearTM}},[_vm._v("Table")])],1)])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"rows":"12"},model:{value:(_vm.schema),callback:function ($$v) {_vm.schema=$$v},expression:"schema"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }