<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Import" class="px-5 py-3">
          <v-form>
            <v-tabs color="primary">
              <v-tab>Marc</v-tab>
              <v-tab>Linear</v-tab>
              <v-tab-item>
                <v-row :dense="dense" class="text-no-wrap" align="start">
                  <v-col cols="12">
                    <div class="d-inline-flex">
                      <v-autocomplete
                        v-model="prefix"
                        label="Prefix"
                        :items="[
                          'cln',
                          'mon',
                          'inv',
                          'ath',
                          'rmf',
                          'prv',
                          'art',
                          'evn',
                          'srv',
                          'sub',
                          'udc',
                          'crw',
                          'dig',
                          'ing',
                          'ind'
                        ]"
                      />
                      <v-btn class="mr-2" @click="getSchema">Schema</v-btn>
                      <v-btn class="mr-2" @click="getMachetaSchema">Macheta</v-btn>
                      <v-btn @click="getTMSchema">Table</v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-inline-flex">
                      <v-text-field v-model="currentPage" label="Pagina" />
                      <v-text-field v-model="maxPage" label="Pagina maxima" />
                      <v-btn @click="importRecords">{{ importing ? "Stop" : "Import" }}</v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-inline-flex">
                      <v-text-field v-model="recordImport" label="Record" />
                      <v-btn @click="importRecord">Import record</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row :dense="dense" class="text-no-wrap" align="start">
                  <v-col cols="12">
                    <div class="d-inline-flex">
                      <v-text-field v-model="table" label="Prefix" />
                      <v-btn class="mr-2" @click="getLinearSchema">Schema</v-btn>
                      <v-btn class="mr-2" @click="getLinearMacheta">Macheta</v-btn>
                      <v-btn @click="getLinearTM">Table</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
            <v-row>
              <v-col>
                <v-textarea v-model="schema" rows="12" />
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "../../../plugins/axios"
import axiosscream from "../../../plugins/axios-scream"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      schema: "",
      schemaTypes: {},
      currentPage: 1,
      maxPage: 10,
      importing: false,
      recordImport: 0,
      currentRecord: 12,
      prefix: "cln",
      table: "config_MarcDefinition",
      render: 1,
      idGlobal: 200
    }
  },

  computed: {
    companyId() {
      return this.$store.getters.companyId
    }
  },
  created() {
    //this.currentPage = 100000
    // this.insertRecord(73270)
    //this.getSchema()
    //this.getTMSchema()
    //this.getMachetaSchema()
    //this.getDictionary()
    //this.getMarcDefinition()
    //this.getLinearMacheta()
  },
  methods: {
    getDictionary() {
      if (this.currentRecord > 10 && this.currentPage < 53) {
        this.currentRecord = 1
        axiosscream
          .get(
            "table/select/config_Dictionary_Labels?page=" +
              this.currentPage +
              "&perPage=10" +
              "&viewColumns=groupName,labelRou" +
              "&f[0][column]=active&f[0][type]=e&f[0][value]=1" +
              "&options=count&orderBy=groupName"
          )
          .then(response => {
            //console.table(response.data.data)
            this.currentPage++
            response.data.data.map(itm => {
              this.insertDictionary(itm)
            })
          })
          .catch(() => {
            this.currentPage++
            this.currentRecord = 12
            this.getDictionary()
          })
      }
    },
    insertDictionary(itm) {
      const schema = {}
      schema.name = itm.labelRou
      schema.value = itm.groupName
      schema.content = []
      schema.owner = { user: [], company: [], app_type: ["ebib"] }
      axiosscream
        .get(
          "table/select/config_Dictionary?page=1&perPage=10000" +
            "&viewColumns=id,grp,val,rou" +
            "&f[0][column]=act&f[0][type]=e&f[0][value]=1" +
            "&f[0][column]=grp&f[0][type]=e&f[0][value]=" +
            itm.groupName +
            "&options=count&orderBy=grp,ord,val"
        )
        .then(res => {
          //console.table(response.data.data)
          res.data.data.map(val => {
            schema.content.push({ name: val.rou, value: val.val })
          })
          axios
            .post("app_dictionary", schema)
            .then(() => {
              this.currentRecord++
              this.getDictionary()
            })
            .catch(() => {
              this.currentRecord++
              this.getDictionary()
            })
        })
        .catch(() => {
          this.currentRecord++
          this.getDictionary()
        })

      this.$log(this.currentPage + " - " + this.currentRecord)
    },
    getMarcDefinition() {
      axiosscream
        .get(
          "table/select/config_MarcPrefix?page=1&perPage=100000" +
            "&viewColumns=id,active,flexUse,viewCatalog,prefix,category,category_wp,lblRo,parentId,ordPref,level,totalRecords," +
            "acceptRatingCalculation,setRecordName,link,entryElement,notes,maxNoViews,maxNoRatings,maxNoComments,maxNoLoans," +
            "maxNoExtensions,maxNoReservations,lblEn,lastUpdate,keywordTableInsert" +
            "&options=count&orderBy=prefix,category"
        )
        .then(res => {
          //this.$log(res.data.data)
          this.insertMarcDefinition(res.data.data, 0)
          // res.data.data.map(val => {
          //   axios.post("ebib_conf_definition", val)
          // })
        })
    },
    insertMarcDefinition(md, i) {
      this.$log(i)
      axios.post("ebib_conf_prefixes", md[i]).then(() => {
        this.insertMarcDefinition(md, ++i)
      })
    },
    getLinearSchema() {
      const schema = {
        company: {
          type: "objectid",
          data_relation: {
            resource: "app_company",
            field: "_id"
          }
        }
      }
      axiosscream.get("table/struct/" + this.table).then(res => {
        //this.$log("data", res.data)
        let str = ""
        for (const col in res.data) {
          str += col + ","
          let type = "string"
          switch (res.data[col].dtt) {
            case "date":
            case "datetime":
            case "timestamp":
              type = "datetime"
              break

            case "decimal":
            case "float":
              type = "float"
              break
            case "int":
            case "bigint":
            case "integer":
            case "tinyint":
            case "smallint":
              type = "integer"
              break
            case "char":
            case "longtext":
            case "text":
            case "varchar":
              type = "string"
              break
          }
          schema[col] = {
            type: type,
            meta: { ...res.data[col], label: col }
          }
          // if (res.data[col].def != "") {
          //   schema[col].default = res.data[col].def
          // }
        }
        this.$log("cols ", str)
        this.schema = JSON.stringify(schema)
      })
    },
    getLinearMacheta() {
      const rows = []
      axiosscream.get("table/struct/" + this.table).then(res => {
        //this.$log("data", res.data)
        for (const col in res.data) {
          rows.push(res.data[col])
        }
        const cols = []
        rows.map(row => {
          let type = "TextInput"
          switch (row.dtt) {
            case "date":
            case "datetime":
            case "timestamp":
              type = "DateTime"
              break

            case "decimal":
            case "float":
              type = "Numeric"
              break
            case "int":
            case "bigint":
            case "integer":
            case "tinyint":
            case "smallint":
              type = "Numeric"
              break
            case "char":
            case "varchar":
              type = "TextInput"
              break
            case "longtext":
            case "text":
              type = "TextArea"
              break
          }
          cols.push({
            name: row.col,
            key: row.col,
            typeObject: type
          })
        })
        this.schema = JSON.stringify(cols)
      })
    },
    getLinearTM() {
      const rows = []
      axiosscream.get("table/struct/" + this.table).then(res => {
        //this.$log("data", res.data)
        for (const col in res.data) {
          //rows.push(res.data[col])
          rows.push({
            name: col,
            key: col,
            typeObject: "TextInput",
            show: true,
            embed: false,
            filtrable: true,
            sortable: true
          })
        }
        this.schema = JSON.stringify(rows)
      })
    },

    getMachetaSchema() {
      const schema = []
      axiosscream
        .get(
          "table/select/config_MarcDefinition?page=1&perPage=50000" +
            "&viewColumns=id,prefix,cid,fld,sub,lblRo,lblEn,chst,chfi,mnd,ords,rpt,lgt,dty,dtyl,flexObjectType," +
            "flexObjectProp,dts,dtt,dtv,dtlb,dtfcol,dtf,regex,regexInfo,rel,flexEditable,allValuesInOne," +
            "authToAutoGroup,authToPrefix,authCid,authToField,authToSubfield,quickAdd,showTM,showTMOrd,showTMLen" +
            "&f[0][column]=act&f[0][type]=e&f[0][value]=1" +
            "&f[1][column]=virtuality&f[1][type]=w&f[1][value]=1" +
            "&f[2][column]=prefix&f[2][type]=e&f[2][value]=" +
            this.prefix +
            "&f[3][column]=chst&f[3][type]=e&f[3][value]=-1" +
            "&f[4][column]=quickAdd&f[4][type]=w&f[4][value]=0" +
            "&options=count&orderBy=fld,sub"
        )
        .then(response => {
          let child = null
          response.data.data.map(itm => {
            if (itm.sub == ".") {
              itm.sub = "9"
            }
            if (itm.sub == "") {
              if (child) {
                schema.push(child)
              }
              child = {
                name: itm.lblRo,
                key: itm.rpt == 1 ? "" : itm.fld + "[]",
                typeObject: "Block",
                show: true,
                array: false,
                children: []
              }
            } else {
              this.$log("mac " + itm.fld + "-" + itm.sub)
              child.children.push({
                name: itm.lblRo,
                key: itm.fld + "." + itm.sub,
                typeObject: "TextInput"
              })
            }
          })
          if (child) {
            schema.push(child)
          }
          console.table(schema)
          //this.$log(JSON.stringify(schema))
          this.schema = JSON.stringify(schema)
        })
    },
    getTMSchema() {
      const schema = []
      axiosscream
        .get(
          "table/select/config_MarcDefinition?page=1&perPage=50000" +
            "&viewColumns=id,prefix,cid,fld,sub,lblRo,lblEn,chst,chfi,mnd,ords,rpt,lgt,dty,dtyl,flexObjectType," +
            "flexObjectProp,dts,dtt,dtv,dtlb,dtfcol,dtf,regex,regexInfo,rel,flexEditable,allValuesInOne," +
            "authToAutoGroup,authToPrefix,authCid,authToField,authToSubfield,quickAdd,showTM,showTMOrd,showTMLen" +
            "&f[0][column]=act&f[0][type]=e&f[0][value]=1" +
            "&f[1][column]=virtuality&f[1][type]=w&f[1][value]=1" +
            "&f[2][column]=prefix&f[2][type]=e&f[2][value]=" +
            this.prefix +
            "&f[3][column]=chst&f[3][type]=e&f[3][value]=-1" +
            "&f[4][column]=showTM&f[4][type]=e&f[4][value]=2" +
            "&options=count&orderBy=showTMOrd"
        )
        .then(response => {
          response.data.data.map(itm => {
            if (itm.sub == ".") {
              itm.sub = "9"
            }
            schema.push({
              name: itm.lblRo,
              //key: "fields." + itm.fld + "." + itm.sub,
              key: "fields." + (itm.fld == "299" ? "200" : itm.fld) + "." + itm.sub + ".val",
              typeObject: "TextInput",
              show: true,
              embed: false,
              filtrable: true,
              sortable: true
            })
          })
          const sch = [
            {
              name: "",
              key: "",
              typeObject: "Table",
              show: true,
              addPopUp: true,
              editPopUp: true,
              viewTypes: ["list", "details", "module"],
              showHeader: true,
              showPagination: true,
              showAdd: true,
              showAction: true,
              filtrable: true,
              sortable: true,
              actions: ["edit", "delete", "select"],
              showSelect: false,
              height: "",
              children: schema
            }
          ]
          //console.table(schema)
          //this.$log(JSON.stringify(schema))
          this.schema = JSON.stringify(sch)
          axios
            .get('app_view?where={"collection":"marc_' + this.prefix + '","type":"table","view_type":"marc"}')
            .then(res => {
              this.$log("appschema res ", res)
              const recs = res.data._items[0]
              const et = recs._etag
              recs._etag = undefined
              recs._links = undefined
              recs._version = undefined
              recs._latest_version = undefined
              recs._created = undefined
              recs._updated = undefined
              recs.content = sch
              axios.put("app_view/" + recs._id, recs, {
                headers: {
                  "If-Match": et
                }
              })
            })
        })
    },
    importRecords() {
      this.$log("import")
      if (this.importing) {
        this.importing = false
      } else {
        this.importing = true
        this.currentRecord = 12
        this.getSchema("import")
      }
    },
    importRecord() {
      this.$log("import")
      this.importing = false
      this.currentRecord = 1
      this.getSchema("importRecord")
    },
    getSchema(type) {
      //const fields = {}
      const subfields = {}
      const schema = {
        company: {
          type: "objectid",
          data_relation: {
            resource: "app_company",
            field: "_id"
          }
        },
        id: {
          type: "integer",
          unique: true
        },
        ctg: {
          type: "string"
        },
        fields: {
          allow_unknown: true,
          type: "dict",
          schema: {}
        }
      }
      //const fields = {} //schema.fields.schema.schema
      const marcPrefix = {}
      axios.get("ebib_conf_prefixes/?max_results=10000&page=1").then(responseMP => {
        responseMP.data._items.map(itm => {
          //marcPrefix[itm.id] = itm.category
          marcPrefix[itm.id] = itm
        })
        axios
          .get(
            'ebib_conf_definition/?where={"prefix":"' +
              this.prefix +
              '","act":1,"virtuality":{"$ne":1},"chst":-1}&sort=[("fld",1),("sub",1)]&max_results=10000&page=1'
          )
          .then(response => {
            this.$log("res ", response)
            response.data._items.map(itm => {
              if (itm.fld != "299aa") {
                if (itm.sub == "") {
                  subfields[itm.fld] = {}
                  schema.fields.schema[itm.fld] = {
                    type: "list",
                    nullable: true,
                    meta: { label: itm.lblRo, labelEn: itm.lblEn, repeatable: itm.rpt },
                    schema: {
                      allow_unknown: true,
                      type: "dict",
                      schema: {
                        ord: { type: "integer" }
                      }
                    }
                  }
                  this.schemaTypes[itm.fld] = "dict"
                } else {
                  let type = "string"
                  if (itm.dtf == "boolean01" || itm.dtf == "true_false") {
                    type = "boolean"
                    // } else if (itm.dts == "auth") {
                    //   type = "objectid"
                  } else {
                    switch (itm.dty) {
                      case "date":
                      case "datetime":
                        type = "datetime"
                        break
                      case "decimal":
                      case "float":
                        type = "float"
                        break
                      case "bigint":
                      case "integer":
                      case "tinyint":
                        type = "integer"
                        break
                      case "char":
                      case "longtext":
                      case "text":
                      case "varchar":
                        type = "string"
                        break
                    }
                  }
                  this.schemaTypes[itm.fld + itm.sub] = type
                  // if (type == "objectid") {
                  //   type = "integer"
                  // }
                  const sch = {
                    type: type,
                    meta: {
                      label: itm.lblRo,
                      labelEn: itm.lblEn,
                      mandatory: itm.mnd,
                      repeatable: itm.rpt,
                      length: itm.dtyl,
                      component: itm.flexObjectType,
                      editable: itm.flexEditable,
                      regex_info: itm.regexInfo
                    }
                  }
                  //this.$log("type " + itm.fld + itm.sub + " - " + type)
                  if (itm.dts == "auth") {
                    sch.meta.component = "Link"
                    // sch.data_relation = {
                    //   resource: "ebib_marc_" + itm.authToPrefix,
                    //   field: "id",
                    //   embeddable: true
                    // }
                    // schema.fields.schema[itm.fld].schema.schema.src_id = {
                    //   type: "string"
                    // }
                    // sch.src_id = {
                    //   type: "string"
                    // }
                    let re = "ebib_marc_" + itm.authToPrefix
                    if (itm.authToPrefix == "med") {
                      //re = "ebib_files"
                      re = "ebib_media"
                    }
                    schema.fields.schema[itm.fld].schema.schema.link = {
                      type: "objectid",
                      data_relation: {
                        resource: re,
                        field: "_id"
                      }
                    }
                  }
                  if (itm.mnd == "1") {
                    //sch.required = true
                  }
                  if (itm.regex != "") {
                    sch.regex = itm.regex
                  }
                  if (itm.dtf != "") {
                    sch.meta.dictionary = itm.dtf
                  }
                  if (itm.authToField != "") {
                    sch.meta.to_prefix = itm.authToPrefix
                    let tof = itm.authToField
                    if (itm.authToField == "299") {
                      if (marcPrefix[itm.authCid].entryElement.substring(0, 3) != "299") {
                        tof = marcPrefix[itm.authCid].entryElement.substring(0, 3)
                      } else {
                        tof = "200"
                      }
                    }
                    sch.meta.to_field = tof
                    // itm.authToField == "299" ? marcPrefix[itm.authCid].entryElement.substring(0, 3) : itm.authToField
                    sch.meta.to_subfield = itm.authToSubfield
                    sch.meta.to_category = marcPrefix[itm.authCid] ? marcPrefix[itm.authCid].category : ""
                  }
                  if (itm.sub == ".") {
                    schema.fields.schema[itm.fld].schema.schema.ind1 = sch
                  } else if (itm.sub == "-") {
                    schema.fields.schema[itm.fld].schema.schema.ind2 = sch
                  } else {
                    //subfields[itm.fld][itm.sub] = sch
                    schema.fields.schema[itm.fld].schema.schema[itm.sub] = {
                      type: "list",
                      schema: {
                        type: "dict",
                        schema: {
                          ord: {
                            type: "number"
                          },
                          src_id: itm.dts == "auth" ? { type: "string" } : undefined,
                          val: sch
                        }
                      }
                    }
                  }
                  //this.$log("sch " + itm.fld)
                  //this.$log("sch " + schema[itm.fld])
                  //this.$log("sch " + schema[itm.fld].schema)
                }
              }
            })
            schema.unknown_fields = {
              meta: {
                label: "Unknown fields"
              },
              type: "list",
              schema: {
                type: "dict",
                allow_unknown: true
              }
            }
            if (type == "import") {
              this.getPageRecords()
              //this.currentPage = 8432
              //this.insertRecord(73270)
            } else if (type == "importRecord") {
              this.insertRecord(this.recordImport)
            } else {
              this.schema = JSON.stringify(schema)
              //this.$log("schema", schema)
              //this.$log("schema", JSON.stringify(schema))
              axios.get('app_schema?where={"collection":"marc_' + this.prefix + '"}').then(res => {
                this.$log("appschema res ", res)
                const recs = res.data._items[0]
                const et = recs._etag
                recs._etag = undefined
                recs._links = undefined
                recs._version = undefined
                recs._latest_version = undefined
                recs._created = undefined
                recs._updated = undefined
                recs.content = schema
                axios.put("app_schema/" + recs._id, recs, {
                  headers: {
                    "If-Match": et
                  }
                })
              })
            }
          })
      })
    },
    getSchemaRemote(type) {
      //const fields = {}
      const subfields = {}
      const schema = {
        company: {
          type: "objectid",
          data_relation: {
            resource: "app_company",
            field: "_id"
          }
        },
        id: {
          type: "integer",
          unique: true
        },
        ctg: {
          type: "string"
        },
        fields: {
          type: "dict",
          schema: {}
        }
      }
      //const fields = {} //schema.fields.schema.schema
      const marcPrefix = {}
      axiosscream
        .get("table/select/config_MarcPrefix?page=1&perPage=50000" + "&viewColumns=id,category")
        .then(responseMP => {
          responseMP.data.data.map(itm => {
            marcPrefix[itm.id] = itm.category
          })
          axiosscream
            .get(
              "table/select/config_MarcDefinition?page=1&perPage=50000" +
                "&viewColumns=id,prefix,cid,fld,sub,lblRo,lblEn,chst,chfi,mnd,ords,rpt,lgt,dty,dtyl,flexObjectType," +
                "flexObjectProp,dts,dtt,dtv,dtlb,dtfcol,dtf,regex,regexInfo,rel,flexEditable,allValuesInOne," +
                "authToAutoGroup,authToPrefix,authCid,authToField,authToSubfield,quickAdd,showTM,showTMOrd,showTMLen" +
                "&f[0][column]=act&f[0][type]=e&f[0][value]=1" +
                "&f[1][column]=virtuality&f[1][type]=w&f[1][value]=1" +
                "&f[2][column]=prefix&f[2][type]=e&f[2][value]=" +
                this.prefix +
                "&f[3][column]=chst&f[3][type]=e&f[3][value]=-1" +
                "&options=count&orderBy=prefix,fld,sub"
            )
            .then(response => {
              response.data.data.map(itm => {
                if (itm.fld != "299aa") {
                  if (itm.sub == "") {
                    subfields[itm.fld] = {}
                    schema.fields.schema[itm.fld] = {
                      type: "list",
                      nullable: true,
                      meta: { label: itm.lblRo, labelEn: itm.lblEn, repeatable: itm.rpt },
                      schema: {
                        type: "dict",
                        schema: {
                          ord: { type: "integer" }
                        }
                      }
                    }
                    this.schemaTypes[itm.fld] = "dict"
                  } else {
                    let type = "string"
                    if (itm.dtf == "boolean01" || itm.dtf == "true_false") {
                      type = "boolean"
                      // } else if (itm.dts == "auth") {
                      //   type = "objectid"
                    } else {
                      switch (itm.dty) {
                        case "date":
                        case "datetime":
                          type = "datetime"
                          break
                        case "decimal":
                        case "float":
                          type = "number"
                          break
                        case "bigint":
                        case "integer":
                        case "tinyint":
                          type = "integer"
                          break
                        case "char":
                        case "longtext":
                        case "text":
                        case "varchar":
                          type = "string"
                          break
                      }
                    }
                    this.schemaTypes[itm.fld + itm.sub] = type
                    // if (type == "objectid") {
                    //   type = "integer"
                    // }
                    const sch = {
                      type: type,
                      meta: {
                        label: itm.lblRo,
                        labelEn: itm.lblEn,
                        mandatory: itm.mnd,
                        repeatable: itm.rpt,
                        length: itm.dtyl,
                        component: itm.flexObjectType,
                        editable: itm.flexEditable,
                        regex_info: itm.regexInfo
                      }
                    }
                    //this.$log("type " + itm.fld + itm.sub + " - " + type)
                    if (itm.dts == "auth") {
                      sch.meta.component = "Link"
                      // sch.data_relation = {
                      //   resource: "ebib_marc_" + itm.authToPrefix,
                      //   field: "id",
                      //   embeddable: true
                      // }
                      // schema.fields.schema[itm.fld].schema.schema.src_id = {
                      //   type: "string"
                      // }
                      sch.src_id = {
                        type: "string"
                      }
                      schema.fields.schema[itm.fld].schema.schema.link = {
                        type: "objectid",
                        data_relation: {
                          resource: "ebib_marc_" + itm.authToPrefix,
                          field: "_id"
                        }
                      }
                    }
                    if (itm.mnd == "1") {
                      //sch.required = true
                    }
                    if (itm.regex != "") {
                      sch.regex = itm.regex
                    }
                    if (itm.dtf != "") {
                      sch.meta.dictionary = itm.dtf
                    }
                    if (itm.authToField != "") {
                      sch.meta.to_prefix = itm.authToPrefix
                      sch.meta.to_field = itm.authToField == "299" ? "200" : itm.authToField
                      sch.meta.to_subfield = itm.authToSubfield
                      sch.meta.to_category = marcPrefix[itm.authCid] ? marcPrefix[itm.authCid] : ""
                    }
                    if (itm.sub == ".") {
                      schema.fields.schema[itm.fld].schema.schema.ind1 = sch
                    } else if (itm.sub == "-") {
                      schema.fields.schema[itm.fld].schema.schema.ind2 = sch
                    } else {
                      //subfields[itm.fld][itm.sub] = sch
                      schema.fields.schema[itm.fld].schema.schema[itm.sub] = {
                        type: "list",
                        schema: {
                          type: "dict",
                          schema: {
                            ord: {
                              type: "number"
                            },
                            val: sch
                          }
                        }
                      }
                    }
                    //this.$log("sch " + itm.fld)
                    //this.$log("sch " + schema[itm.fld])
                    //this.$log("sch " + schema[itm.fld].schema)
                  }
                }
              })
              if (type == "import") {
                this.getPageRecords()
                //this.currentPage = 8432
                //this.insertRecord(73270)
              } else if (type == "importRecord") {
                this.insertRecord(this.recordImport)
              } else {
                this.schema = JSON.stringify(schema)
                this.$log("schema", schema)
                //this.$log("schema", JSON.stringify(schema))
                axios.get('app_schema?where={"collection":"marc_' + this.prefix + '"}').then(res => {
                  this.$log("appschema res ", res)
                  const recs = res.data._items[0]
                  const et = recs._etag
                  recs._etag = undefined
                  recs._links = undefined
                  recs._version = undefined
                  recs._latest_version = undefined
                  recs._created = undefined
                  recs._updated = undefined
                  recs.content = schema
                  axios.put("app_schema/" + recs._id, recs, {
                    headers: {
                      "If-Match": et
                    }
                  })
                })
              }
            })
        })
    },
    getSchemaFields(type) {
      const fields = {}
      const subfields = {}
      const schema = {
        company: {
          type: "objectid",
          data_relation: {
            resource: "app_company",
            field: "_id"
          }
        },
        id: {
          type: "integer",
          unique: true
        },
        ctg: {
          type: "string"
        },
        fields: {
          type: "list",
          schema: {
            type: "dict",
            schema: fields
          }
        }
      }
      //const fields = {} //schema.fields.schema.schema
      axiosscream
        .get(
          "table/select/config_MarcDefinition?page=1&perPage=50000" +
            "&viewColumns=id,prefix,cid,fld,sub,lblRo,lblEn,chst,chfi,mnd,ords,rpt,lgt,dty,dtyl,flexObjectType," +
            "flexObjectProp,dts,dtt,dtv,dtlb,dtfcol,dtf,regex,regexInfo,rel,flexEditable,allValuesInOne," +
            "authToAutoGroup,authToPrefix,authCid,authToField,authToSubfield,quickAdd,showTM,showTMOrd,showTMLen" +
            "&f[0][column]=act&f[0][type]=e&f[0][value]=1" +
            "&f[1][column]=virtuality&f[1][type]=w&f[1][value]=1" +
            "&f[2][column]=prefix&f[2][type]=e&f[2][value]=" +
            this.prefix +
            "&f[3][column]=chst&f[3][type]=e&f[3][value]=-1" +
            "&options=count&orderBy=prefix,fld,sub"
        )
        .then(response => {
          response.data.data.map(itm => {
            if (itm.sub == "") {
              subfields[itm.fld] = {}
              fields[itm.fld] = {
                type: "dict",
                meta: { label: itm.lblRo, labelEn: itm.lblEn, repeatable: itm.rpt },
                schema: {
                  subfields: {
                    type: "list",
                    meta: { label: itm.lblRo, labelEn: itm.lblEn, repeatable: itm.rpt },
                    schema: { type: "dict", schema: subfields[itm.fld] }
                  }
                }
              }

              this.schemaTypes[itm.fld] = "dict"
              //}
            } else {
              let type = "string"
              if (itm.dtf == "boolean01" || itm.dtf == "true_false") {
                type = "boolean"
              } else if (itm.dts == "auth") {
                type = "objectid"
              } else {
                switch (itm.dty) {
                  case "date":
                  case "datetime":
                    type = "datetime"
                    break

                  case "decimal":
                  case "float":
                    type = "float"
                    break
                  case "bigint":
                  case "integer":
                  case "tinyint":
                    type = "integer"
                    break
                  case "char":
                  case "longtext":
                  case "text":
                  case "varchar":
                    type = "string"
                    break
                }
              }
              this.schemaTypes[itm.fld + itm.sub] = type
              if (type == "objectid") {
                type = "integer"
              }

              const sch = {
                type: type,
                meta: {
                  label: itm.lblRo,
                  labelEn: itm.lblEn,
                  mandatory: itm.mnd,
                  repeatable: itm.rpt,
                  length: itm.dtyl,
                  component: itm.flexObjectType,
                  editable: itm.flexEditable,
                  regex_info: itm.regexInfo
                }
              }
              //this.$log("type " + itm.fld + itm.sub + " - " + type)

              if (itm.dts == "auth") {
                // sch.data_relation = {
                //   resource: "ebib_marc_" + itm.authToPrefix,
                //   field: "id",
                //   embeddable: true
                // }
              }
              if (itm.mnd == "1") {
                //sch.required = true
              }
              if (itm.regex != "") {
                sch.regex = itm.regex
              }
              if (itm.dtf != "") {
                sch.meta.dictionary = itm.dtf
              }
              if (itm.sub == ".") {
                fields[itm.fld].schema.ind1 = sch
              } else if (itm.sub == "-") {
                fields[itm.fld].schema.ind2 = sch
              } else {
                subfields[itm.fld][itm.sub] = sch
              }
              //this.$log("sch " + itm.fld)
              //this.$log("sch " + schema[itm.fld])
              //this.$log("sch " + schema[itm.fld].schema)
              // if (schema[itm.fld].schema.type == "dict") {
              //   schema[itm.fld].schema.schema[itm.sub] = sch
              // } else {
              //   schema[itm.fld].schema[itm.sub] = sch
              // }
            }
          })
          if (type == "import") {
            this.getPageRecords()
            //this.currentPage = 8432
            //this.insertRecord(73270)
          } else if (type == "importRecord") {
            this.insertRecord(this.recordImport)
          } else {
            this.schema = JSON.stringify(schema)
            this.$log("schema", schema)
            this.$log("schema", JSON.stringify(schema))
          }
        })
    },
    getPageRecords() {
      if (this.currentRecord > 10 && this.currentPage < this.maxPage && this.importing) {
        this.$log("this.currentPage ", this.currentPage)
        this.currentRecord = 1
        axiosscream
          .get(
            "marc/search/compact/" +
              this.prefix +
              "?page=" +
              this.currentPage +
              "&viewColumns=200a" +
              "&perPage=10" +
              "&options=count"
          )
          .then(res => {
            //this.$log("response ", res)
            this.currentPage++
            res.data.data.map(itm => {
              //this.$log("id ", itm.id)
              this.insertRecord(itm.id)
            })
          })
          .catch(() => {
            this.currentPage++
            this.currentRecord = 12
            this.getPageRecords()
          })
      }
    },
    insertRecord(id) {
      const rec = {}
      rec.id = id
      rec.company = this.companyId
      rec.fields = {}
      axiosscream
        .get("marc/object/id/" + this.prefix + "/" + id)
        .then(response => {
          //this.$log("response ", response)
          if (response.data.ctg) {
            rec.ctg = response.data.ctg
          }
          for (const fld in response.data.data) {
            for (const ord in response.data.data[fld]) {
              const r = {}
              //r[fld] = { subfields: [] }
              r[fld] = {}
              for (let i = 0; i < response.data.data[fld][ord].sub.length; i++) {
                const sub = {}
                if (fld + response.data.data[fld][ord].sub[i].sub == "856ddd") {
                  // this.$log("media response1 " + response.data.data[fld][ord].sub[i].value)
                  // axios
                  //   .get(
                  //     'ebib_media/?projection={"file":0}&where={"file_name":"' +
                  //       response.data.data[fld][ord].sub[i].value +
                  //       '"}'
                  //   )
                  //   .then(res => {
                  //     this.$log("media response2 " + res.data._items[0]._id)
                  //     r[fld].link = res.data._items[0]._id
                  //   })
                  axiosscream
                    .get(
                      "media/crop?path=" +
                        response.data.data[fld][ord].sub[i].value +
                        "&x=240&y=120&original=1&prefix=" +
                        this.prefix,
                      {
                        responseType: "blob"
                      }
                    )
                    .then(img => {
                      const fd = new FormData()
                      fd.append("file", img.data)
                      fd.append("content_type", "image/jpeg")
                      fd.append("company", this.companyId)
                      // fd.append("files.name", "n1")
                      // fd.append("files.name", "n2")
                      // fd.append("files.file", "f1")
                      // fd.append("files.file", "f2")
                      //fd.append("files", img.data)
                      fd.append("file_name", response.data.data[fld][ord].sub[i].value)
                      fd.append("name", response.data.data[fld][ord].sub[i].value)
                      axios.post("ebib_media", fd).then(() => {
                        //this.$log("media response ", rm)
                      })
                    })
                }
                if (response.data.data[fld][ord].sub[i].sub == ".") {
                  r[fld].ind1 = response.data.data[fld][ord].sub[i].value
                } else if (response.data.data[fld][ord].sub[i].sub == "-") {
                  r[fld].ind2 = response.data.data[fld][ord].sub[i].value
                } else {
                  if (!r[fld][response.data.data[fld][ord].sub[i].sub]) {
                    r[fld][response.data.data[fld][ord].sub[i].sub] = []
                  }
                  if (
                    this.schemaTypes[fld + response.data.data[fld][ord].sub[i].sub] == "datetime" &&
                    response.data.data[fld][ord].sub[i].value.length < 12
                  ) {
                    sub[response.data.data[fld][ord].sub[i].sub] =
                      response.data.data[fld][ord].sub[i].value + " 00:00:00"
                    r[fld][response.data.data[fld][ord].sub[i].sub].push({
                      ord: 0,
                      val: response.data.data[fld][ord].sub[i].value + " 00:00:00"
                    })
                  } else if (this.schemaTypes[fld + response.data.data[fld][ord].sub[i].sub] == "objectid22222") {
                    if (response.data.data[fld][ord].sub[i].aid) {
                      sub[response.data.data[fld][ord].sub[i].sub] = response.data.data[fld][ord].sub[i].aid
                    }
                  } else {
                    sub[response.data.data[fld][ord].sub[i].sub] = response.data.data[fld][ord].sub[i].value
                    r[fld][response.data.data[fld][ord].sub[i].sub].push({
                      ord: 0,
                      val: response.data.data[fld][ord].sub[i].value
                    })
                  }
                  //r[fld].subfields.push(sub)
                }
              }

              if (this.schemaTypes[fld]) {
                if (rec.fields[fld] == undefined) {
                  rec.fields[fld] = []
                }
                rec.fields[fld].push(r[fld])
              }
            }
          }
          //this.currentRecord++
          //this.getPageRecords()
          //this.$log("rec ", rec)
          axios
            .post("ebib_marc_" + this.prefix, rec)
            .then(() => {
              this.currentRecord++
              this.getPageRecords()
              //this.$log("add response ", response)
            })
            .catch(() => {
              this.currentRecord++
              this.getPageRecords()
            })
        })
        .catch(() => {
          this.currentRecord++
          this.getPageRecords()
        })
    },
    insertRecordFields(id) {
      const rec = { fields: [] }
      rec.id = id
      rec.company = this.companyId
      axiosscream
        .get("marc/object/id/" + this.prefix + "/" + id)
        .then(response => {
          //this.$log("response ", response)
          if (response.data.ctg) {
            rec.ctg = response.data.ctg
          }
          for (const fld in response.data.data) {
            for (const ord in response.data.data[fld]) {
              const r = {}
              r[fld] = { subfields: [] }
              for (let i = 0; i < response.data.data[fld][ord].sub.length; i++) {
                const sub = {}
                if (fld + response.data.data[fld][ord].sub[i].sub == "856d") {
                  // axiosscream
                  //   .get(
                  //     "media/crop?path=" +
                  //       response.data.data[fld][ord].sub[i].value +
                  //       "&x=240&y=120&original=1&prefix=" +
                  //       this.prefix,
                  //     {
                  //       responseType: "blob"
                  //     }
                  //   )
                  //   .then(img => {
                  //     const fd = new FormData()
                  //     fd.append("file", img.data)
                  //     fd.append("id", id)
                  //     fd.append("link", response.data.data[fld][ord].sub[i].value)
                  //     axios.post("ebib_media", fd).then(() => {
                  //       //this.$log("media response ", rm)
                  //     })
                  //   })
                }
                if (response.data.data[fld][ord].sub[i].sub == ".") {
                  r[fld].ind1 = response.data.data[fld][ord].sub[i].value
                } else if (response.data.data[fld][ord].sub[i].sub == "-") {
                  r[fld].ind2 = response.data.data[fld][ord].sub[i].value
                } else {
                  if (
                    this.schemaTypes[fld + response.data.data[fld][ord].sub[i].sub] == "datetime" &&
                    response.data.data[fld][ord].sub[i].value.length < 12
                  ) {
                    sub[response.data.data[fld][ord].sub[i].sub] =
                      response.data.data[fld][ord].sub[i].value + " 00:00:00"
                  } else if (this.schemaTypes[fld + response.data.data[fld][ord].sub[i].sub] == "objectid") {
                    if (response.data.data[fld][ord].sub[i].aid) {
                      sub[response.data.data[fld][ord].sub[i].sub] = response.data.data[fld][ord].sub[i].aid
                    }
                  } else {
                    sub[response.data.data[fld][ord].sub[i].sub] = response.data.data[fld][ord].sub[i].value
                  }
                  r[fld].subfields.push(sub)
                }
              }

              if (this.schemaTypes[fld]) {
                rec.fields.push(r)
              }
            }
          }
          //this.currentRecord++
          //this.getPageRecords()
          //this.$log("rec ", rec)
          axios
            .post("ebib_marc_" + this.prefix, rec)
            .then(() => {
              this.currentRecord++
              this.getPageRecords()
              //this.$log("add response ", response)
            })
            .catch(() => {
              this.currentRecord++
              this.getPageRecords()
            })
        })
        .catch(() => {
          this.currentRecord++
          this.getPageRecords()
        })
    },
    insertRecordExport(id) {
      this.$log("insertRecordExport")
      const rec = {}
      rec.id = id
      rec.company = this.companyId
      axiosscream
        .get("marc/object/export/" + this.prefix + "/" + id)
        .then(response => {
          //this.$log("response ", response)
          //console.table(response.data)
          let lastFiled = "0"
          let r = {}
          response.data.map(itm => {
            //this.$log(itm)
            if (itm.fld != lastFiled) {
              if (lastFiled != "0") {
                if (this.schemaTypes[lastFiled] == "list") {
                  if (rec[lastFiled]) {
                    rec[lastFiled].push(r)
                  } else {
                    rec[lastFiled] = []
                    rec[lastFiled].push(r)
                  }
                } else {
                  rec[lastFiled] = r
                }
              }
              r = {}
              lastFiled = itm.fld
            }
            if (this.schemaTypes[itm.fld + itm.sub] == "list") {
              if (r[itm.sub]) {
                r[itm.sub].push(itm.val)
              } else {
                r[itm.sub] = []
                r[itm.sub].push(itm.val)
              }
            } else if (this.schemaTypes[itm.fld + itm.sub] == "datetime" && itm.value.length < 12) {
              r[itm.sub] = itm.val + " 00:00:00"
              //} else if (this.schemaTypes[fld + itm.sub] == "objectid") {
            } else {
              r[itm.sub] = itm.val
            }
          })
          this.$log("insertRecordExport22")
          // axios
          //   .post("ebib_marc_" + this.prefix, rec)
          //   .then(() => {
          //     this.currentRecord++
          //     this.getPageRecords()
          //     //this.$log("add response ", response)
          //   })
          //   .catch(() => {
          //     this.currentRecord++
          //     this.getPageRecords()
          //   })
        })
        .catch(() => {
          this.currentRecord++
          this.getPageRecords()
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
