import axios from "axios"
import { EventBus } from "../EventBus"
const axiosScream = axios.create({
  baseURL: "https://api.ebibliophil.ro/api/1.0/ebapi/"
})

axiosScream.defaults.headers.common["Content-type"] = "application/json"
axiosScream.defaults.headers.common["Auth-Token"] = "ABCDEFGHi3klmnop"

axiosScream.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response
  },
  function(error) {
    const salert = { message: error.message, timeOut: 5000, color: "red" }
    if (error.response) {
      if (error.response.data.msg) {
        salert.message = error.response.data.msg
      }
    } else if (error.request) {
      //salert.message = "Bad request"
    } else {
      //salert.message = error.message
    }
    EventBus.$emit("show-alert", salert)
    return Promise.reject(error)
  }
)

export default axiosScream
